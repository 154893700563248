<template>
  <div class="box">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>一键报废</span>
        <el-steps class="steps-wrapper" align-center :active="active">
          <el-step description="填写车主信息" icon="el-icon-s-help"></el-step>
          <el-step description="填写车辆信息" icon="el-icon-s-help"></el-step>
          <el-step description="上传相关图片" icon="el-icon-s-help"></el-step>
          <el-step description="提交成功" icon="el-icon-s-help"></el-step>
        </el-steps>
      </div>
      <div class="box">
        <p>
          <img class="middle-img" src="/img/success_icon2.png" alt="" />
        </p>
        <p class="middle-font-top">提交成功</p>
        <p class="middle-font-bottom">
          <span>{{ time }}秒</span>自动返回个人中心
        </p>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "fourthScrap",
  data() {
    return {
      active: 3,
      time: 3,
      timer: null,
    };
  },
  created() {
    this.timer = setInterval(() => {
      this.time--;
    }, 1000);
    window.sessionStorage.setItem("firstScrap", JSON.stringify({}));
  },
  watch: {
    time(newVal) {
      if (newVal <= 0) {
        clearInterval(this.timer);
        this.$router.push("/personalCenter/personalCenter");
      }
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.box {
  background-color: #f8fcff;
  p {
    text-align: center;
  }
}
.box-card {
  width: 1200px; /* no */
  margin: 20px auto; /* no */
}
.clearfix {
  padding: 0 0 20px 0; /* no */
  font-size: 18px; /* no */
}
.middle-img {
  width: 64px; /* no */
  height: 64px; /* no */
  object-fit: cover;
  margin-top: 100px; /* no */
}
.middle-font-top {
  font-size: 24px; /* no */
  margin: 30px 0 29px 0; /* no */
}
.middle-font-bottom {
  margin: 30px 0 120px 0; /* no */
  span {
    color: #c91c25;
  }
}
/deep/.el-step__description is-finish {
  color: #24a2aa;
}
.steps-wrapper {
  width: 500px; /* no */
  float: right;
  /deep/.is-finish {
    color: #24a2aa !important;
    border-color: #24a2aa;
  }
}
</style>
